
import { defineComponent } from 'vue';
import UndefinedResourceError from '@/types/exceptions/UndefinedResourceError';
import globals from '../../helpers/globals';
import FormButton from '../../components/forms/Button.vue';
import SegmentDataHelper from '../../helpers/SegmentDataHelper';

export default defineComponent({
  name: 'Home',
  mixins: [
    globals,
  ],
  components: {
    FormButton,
  },
  created() {
    const vm = this;

    /* Try to set profile if authenticated */
    this.setProfile(this.$store);

    /* LOAD DEPENDENCIES IF DONT ALREADY EXIST */
    this.$store.dispatch('isLoading', true);

    this.load((data: any) => {
      vm.$store.dispatch('setSelectedSegment', SegmentDataHelper.getObjectFromStore(vm.$store, vm.$route.params.segment as string));

      if (Object.keys(vm.$store.state.selected.segment).length === 0) {
        throw new UndefinedResourceError();
      }

      vm.init();

      // Reinit data in beggining
      Object.keys(vm.$store.state.data).forEach((key: string) => {
        vm.$store.state.data[key].completed = false;
      });
    });
  },
  mounted() {
    /* Scroll page to top (case of mobile phones) */
    this.scrollToTop();
  },
  methods: {
    /* COMPONENT INITIALIZER */
    init() {
      /* FLAG STEP AS UNCOMPLETED */
      this.setStepAsCompleted(this, false);

      /* MOVE PROGRESS BAR TO VALUE */
      this.progress(this);

      /* SHOW SIDE AND NAV BAR IF USER FALLS DIRECTLY INTO CURRENT COMPONENT */
      this.$store.dispatch('toggleProgressBar', false);
      this.$store.dispatch('toggleTopBar', false);
      this.$store.dispatch('toggleSideBar', false);

      /* STOP LOADING */
      this.$store.dispatch('isLoading', false);
    },
    /* LOAD DATA THROUGH APIS */
    load(callback: any) {
      SegmentDataHelper.prepareComponentData(
        this.$store,
        callback,
      );
    },
    goToNextStep() {
      /* FLAG STEP AS COMPLETED BEFORE MOVING TO NEXT SCREEN */
      // this.$store.dispatch('setHomeAsCompleted', true);

      /* MOVE TO NEXT SCREEN */
      this.goToNextScreen(this, {
        segment: this.$store.state.selected.segment.pid,
      });

      this.$store.dispatch('toggleSideBar', true);
    },
  },
});
